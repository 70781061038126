<!-- 职业报告倾向 -->

<template>
  <div class="main">
    <div class="main-title">
      <span
        style="left: 0;padding-left: 2rem; position: absolute;color: #2ea9df"
        >基础版</span
      >
      <span>职业倾向报告</span>
      <div class="print-btn" @click="print()">
        <div class="print-btn-text-wrapper">
          <span>打印</span>
          <span>报告</span>
        </div>
      </div>
    </div>
    <div class="main-parts-wrapper">
      <div class="main-part">
        <div class="part-title">兴趣评测结果</div>
        <div class="part-content">
          <div class="two-columns-wrapper">
            <div class="left-column">
              <p>根据霍兰德代码，您的各职业兴趣类型得分为：</p>
              <table
                class="lj-table lj-table-bordered lj-table-radius lj-table-striped lj-table-hover"
              >
                <thead>
                  <tr>
                    <th>职业兴趣类型</th>
                    <th class="lj-text-center">得分</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>现实型(R)</th>
                    <td class="lj-text-center">{{ holland_r }}分</td>
                  </tr>
                  <tr>
                    <th>研究型(I)</th>
                    <td class="lj-text-center">{{ holland_i }}分</td>
                  </tr>
                  <tr>
                    <th>艺术型(A)</th>
                    <td class="lj-text-center">{{ holland_a }}分</td>
                  </tr>
                  <tr>
                    <th>社会型(S)</th>
                    <td class="lj-text-center">{{ holland_s }}分</td>
                  </tr>
                  <tr>
                    <th>企业型(E)</th>
                    <td class="lj-text-center">{{ holland_e }}分</td>
                  </tr>
                  <tr>
                    <th>常规型(C)</th>
                    <td class="lj-text-center">{{ holland_c }}分</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="right-column">
              <div id="hollandCanvas" style="width: 700px; height: 400px"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-part">
        <div class="part-title">兴趣评测结果解释</div>
        <div class="part-content">
          <table
            class="lj-table lj-table-bordered lj-table-centered lj-table-radius lj-table-striped lj-table-hover"
          >
            <thead>
              <tr>
                <th class="lj-text-middle" style="width: 5em">兴趣类型</th>
                <th class="lj-text-middle" style="width: 5em">您的得分</th>
                <th class="lj-text-middle">共同特征</th>
                <th class="lj-text-middle">典型职业</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="lj-text-middle">现实型（R）</td>
                <td class="lj-text-middle">{{ holland_r }}</td>
                <td class="lj-text-middle">
                  愿意使用工具从事操作性工作，动手能力强，做事手脚灵活，动作协调。偏好于具体任务，不善言辞，做事保守，较为谦虚。缺乏社交能力，通常喜欢独立做事。
                </td>
                <td class="lj-text-middle">
                  喜欢使用工具、机器，需要基本操作技能的工作。对要求具备机械方面才能、体力或从事与物件、机器、工具、运动器材、植物、动物相关的职业有兴趣，并具备相应能力。如：技术性职业（计算机硬件人员、摄影师、制图员、机械装配工），技能性职业（木匠、厨师、技工、修理工、农民、一般劳动）。
                </td>
              </tr>
              <tr>
                <td class="lj-text-middle">研究型 (I）</td>
                <td class="lj-text-middle">{{ holland_i }}</td>
                <td class="lj-text-middle">
                  思想家而非实干家，抽象思维能力强，求知欲强，肯动脑，善思考，不愿动手。喜欢独立的和富有创造性的工作。知识渊博，有学识才能，不善于领导他人。考虑问题理性，做事喜欢精确，喜欢逻辑分析和推理，不断探讨未知的领域。
                </td>
                <td class="lj-text-middle">
                  喜欢智力的、抽象的、分析的、独立的定向任务，要求具备智力或分析才能，并将其用于观察、估测、衡量、形成理论、最终解决问题的工作，并具备相应的能力。如科学研究人员、教师、工程师、电脑编程人员、医生、系统分析员。
                </td>
              </tr>
              <tr>
                <td class="lj-text-middle">艺术型 （A）</td>
                <td class="lj-text-middle">{{ holland_a }}</td>
                <td class="lj-text-middle">
                  有创造力，乐于创造新颖、与众不同的成果，渴望表现自己的个性，实现自身的价值。做事理想化，追求完美，不重实际。具有一定的艺术才能和个性。善于表达、怀旧、心态较为复杂。
                </td>
                <td class="lj-text-middle">
                  喜欢的工作要求具备艺术修养、创造力、表达能力和直觉，并将其用于语言、行为、声音、颜色和形式的审美、思索和感受，具备相应的能力，不善于事务性工作。如艺术方面（演员、导演、艺术设计师、雕刻家、建筑师、摄影家、广告制作人），音乐方面（歌唱家、作曲家、乐队指挥），文学方面（小说家、诗人、剧作家）。
                </td>
              </tr>
              <tr>
                <td class="lj-text-middle">社会型 （S）</td>
                <td class="lj-text-middle">{{ holland_s }}</td>
                <td class="lj-text-middle">
                  喜欢与人交往、不断结交新的朋友、善言谈、愿意教导别人。关心社会问题、渴望发挥自己的社会作用。寻求广泛的人际关系，比较看重社会义务和社会道德。
                </td>
                <td class="lj-text-middle">
                  喜欢要求与人打交道的工作，能够不断结交新的朋友，从事提供信息、启迪、帮助、培训、开发或治疗等事务，并具备相应能力。如:
                  教育工作者（教师、教育行政人员），社会工作者（咨询人员、公关人员）。
                </td>
              </tr>
              <tr>
                <td class="lj-text-middle">企业型 （E）</td>
                <td class="lj-text-middle">{{ holland_e }}</td>
                <td class="lj-text-middle">
                  追求权力、权威和物质财富，具有领导才能。喜欢竞争、敢冒风险、有野心、抱负。为人务实，习惯以利益得失，权利、地位、金钱等来衡量做事的价值，做事有较强的目的性。
                </td>
                <td class="lj-text-middle">
                  喜欢要求具备经营、管理、劝服、监督和领导才能，以实现机构、政治、社会及经济目标的工作，并具备相应的能力。如项目经理、销售人员，营销管理人员、政府官员、企业领导、法官、律师。
                </td>
              </tr>
              <tr>
                <td class="lj-text-middle">常规型 （C）</td>
                <td class="lj-text-middle">{{ holland_c }}</td>
                <td class="lj-text-middle">
                  尊重权威和规章制度，喜欢按计划办事，细心、有条理，习惯接受他人的指挥和领导，自己不谋求领导职务。喜欢关注实际和细节情况，通常较为谨慎和保守，缺乏创造性，不喜欢冒险和竞争，富有自我牺牲精神。
                </td>
                <td class="lj-text-middle">
                  喜欢要求注意细节、精确度、有系统有条理，具有记录、归档、据特定要求或程序组织数据和文字信息的职业，并具备相应能力。如：秘书、办公室人员、记事员、会计、行政助理、图书馆管理员。
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="main-buttons-wrapper">
      <a
        class="lj-btn lj-btn-third lj-btn-block main-button"
        @click="jumpPage('reportCenter')"
        >返回目录</a
      ><a
        class="lj-btn lj-btn-primary lj-btn-block main-button"
        v-if="!questionaireMbtiDone"
        @click="jumpPage('HomeMajorStdMatchPage')"
        >继续测评</a
      ><a
        class="lj-btn lj-btn-primary lj-btn-block main-button"
        v-if="questionaireMbtiDone"
        @click="jumpPage('HomeMajorStdResultPage2')"
        >下一页</a
      >
    </div>
  </div>
</template>

<script>
import './../../../../Public/utils/chartjs/Chart'
import './../../../../Public/utils/highcharts/highcharts'
import 'vue-chartjs'
import 'chart.js'
import { queryHolland, queryMajorDone } from './../../../../api/major'

export default {
  data: function () {
    return {
      holland_r: 40,
      holland_i: 60,
      holland_a: 20,
      holland_s: 45,
      holland_e: 78,
      holland_c: 81,

      questionaireMbtiDone: true
    }
  },
  computed: {
    isMobile: function () {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    }
  },
  methods: {
    jumpPage: function (dest) {
      dest === '/'
        ? this.$router.replace({ path: dest })
        : this.$router.push({ path: dest })
    },
    print: function () {
      this.$router.push({
        path: 'HomeMajorStdPrint'
      })
    }
  },
  mounted: function () {
    if (this.isMobile !== null) {
      this.$router.push({
        path: 'HomeMajorStdResultPage1Mobile'
      })
      return
    }
    let o = this
    queryHolland({}).then(res => {
      if (res.rescode == 200) {
        o.holland_r = parseInt(res.data.holland_r)
        o.holland_i = parseInt(res.data.holland_i)
        o.holland_a = parseInt(res.data.holland_a)
        o.holland_s = parseInt(res.data.holland_s)
        o.holland_e = parseInt(res.data.holland_e)
        o.holland_c = parseInt(res.data.holland_c)

        var leidaGraph = this.$echarts.init(
          document.getElementById('hollandCanvas')
        )
        var option
        option = {
          color: ['#f17c67'],
          radar: [
            {
              color: ['#000000'],
              indicator: [
                { name: '现实(R)', max: 100 },
                { name: '研究(I)', max: 100 },
                { name: '艺术(A)', max: 100 },
                { name: '社会(S)', max: 100 },
                { name: '企业(E)', max: 100 },
                { name: '常规(C)', max: 100 }
              ]
            }
          ],
          series: [
            {
              type: 'radar',
              data: [
                {
                  value: [
                    o.holland_r,
                    o.holland_i,
                    o.holland_a,
                    o.holland_s,
                    o.holland_e,
                    o.holland_c
                  ],
                  name: '测评结果'
                }
              ]
            }
          ]
        }
        option && leidaGraph.setOption(option)

        queryMajorDone({}).then(res => {
          if (res.rescode == 200) {
            this.questionaireMbtiDone = res.cur_q > 53 || res.cur_q === -1
          }
        })
      }
    })
  }
}
</script>

<style lang="less" scoped>
.main-parts-wrapper {
  flex-direction: column;
}
.main-title {
  padding: 0;
}
</style>
